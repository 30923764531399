.container {
  display: flex;
  align-items: center;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-icon-container {
  width: 26px;
  height: 26px;
  border-radius: 3px;
  margin-bottom: 2px;
}

.status-count {
  margin: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}